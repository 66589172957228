<template>
  <div class="businessWrap">
		<div class="swiperHead">
			<div class="swiperBtn" @click="checkCurrent(item,index)" v-for="item,index in tabbarList" :key="index">
				<div class="text" :class="currentIndex == index ? 'currentText' : ''">{{ item.name }}</div>
			</div>
		</div>
		<div class="businessContent" id="businessContent" @scroll="handleScroll($event)" v-if="currentIndex == 0">
			<div class="orderNodata" v-if="equityInList.length==0">
				<img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/nodata.png"></img>
				<div>您还没有相关的订单哦</div>
			</div> 
			<div class="scrolldiv" v-else>
				<div class="swiperItemWrap">
					<div class="swiperItem" v-for="(item, index) in equityInList" :key="index">
						<div class="swiperItem_left">
							<img class="left_icon"
							:src="`https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/${
								item.item_name == '优生活会员' ? 'icon_02' : 'icon_01'}.png`"></img>
							<div class="swiperItem_left_title">
								<div>{{ item.item_long_name }}</div>
								<div>连续包月订购中</div>
							</div>
						</div>
						<div class="swiperItem_right" @click="unsignClick(item.item_name)">退订</div>
					</div>
				</div>
			</div>
		</div>
		<div class="businessContent" id="businessContent" @scroll="handleScroll($event)" v-if="currentIndex == 1">
			<div class="orderNodata" v-if="equityEndList.length==0">
				<img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/nodata.png"></img>
				<div>您还没有相关的订单哦</div>
			</div>
			<div class="scrolldiv" v-else>
				<div class="swiperItemWrap">
					<div class="swiperItem" v-for="(item, index) in equityEndList" :key="index">
						<div class="swiperItem_left">
							<img class="left_icon" :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/${
								item.item_name == '优生活会员' ? 'icon_02' : 'icon_01'}.png`"></img>
							<div class="swiperItem_left_title">
								<div>{{ item.item_long_name }}</div>
								<div>退订时间：{{ item.unsign_time }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-alert
      		:visibility.sync="alert.visibility"
			:title="alert.title"
			:message="alert.message"
			:rulestype="alert.rulestype"
			@handleSubmit="handleSubmit"
			/>
	</div>
</template>

<script>
import VAlert from "./dialog/alert.vue";
import { record ,alipayUnsign} from "@/api/huimo/receive.js";
import { mapGetters } from "vuex";

export default {
  components: {
    VAlert
  },
  data() {
    return {
      alert: {
        visibility: false,
        title: "",
        message: "",
      },
      equityInList:[],
	  equityEndList:[],
      page: [1,1],
      orderStatus:'订购中',
      currentIndex:0,
	  tabbarList:[{
		name:'订购中'
	  },{name:'已退订'}],
	  buyItemStatusTip: '', 
    };
  },
  computed: {
    ...mapGetters(["taiyue_token", "taiyue_user_info" ,"taiyue_wechat_open_id"]),
  },
  created() {
	this.recordList();
  },
  methods: {
    async recordList() {
      this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        await record(this.taiyue_token,{ 'page': this.page[this.currentIndex], 'data_type': 'order', 'order_status': this.orderStatus })
        .then(res =>{
          if (res.code == 0) {
			this.pageCount = res.pages;
			if(this.page[this.currentIndex] == 1){
				if(this.currentIndex == 0){
					this.equityInList = res.record;
				}else if(this.currentIndex == 1){
					this.equityEndList = res.record;
				}
			}else{
				if(this.currentIndex == 0){
					this.equityInList = this.equityInList.concat(res.record);
				}else if(this.currentIndex == 1){
					this.equityEndList = this.equityEndList.concat(res.record);
				}
			}
              this.$toast.clear();
          } else {
              this.$toast.clear();
          }
        }).catch(err =>{
          this.$toast.clear();
		  this.$router.replace({
            path: "/hz_huimo/receive/login",
            query: {
              wechat_open_id: this.taiyue_wechat_open_id,
			  from: "pact"
            },
          });
        })
    },
	checkCurrent(item,index){
		this.currentIndex = index;
		this.orderStatus = item.name;
		this.recordList();
	},
    unsignClick(name){
		this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
		alipayUnsign(this.taiyue_token,{'item_name': name}).then((res)=>{
			 if (res.code == 0) {
              this.buyItemStatusTip = '解约成功';
              this.exchangeStatus = true;
              this.alert = {
                visibility: true,
                message: res.message,
                title: this.buyItemStatusTip,
              };
			} else {
				this.buyItemStatusTip = '解约失败';
				this.alert = {
					visibility: true,
					message: res.message,
					title: this.buyItemStatusTip,
				};
			}
			setTimeout(() => {
				this.recordList();
			}, 2000)
			this.$toast.clear();
		}).catch((err)=>{
			
			this.$toast.clear();
		})
    },
	handleSubmit(){
      this.visibility = false;
    },
	 // 获取页面滚动距离
	 handleScroll(e) {
		let height = document.getElementById("businessContent").offsetHeight;
		let scrollTop = e.srcElement.scrollTop || e.target.scrollTop;
		let scrollHeight = e.srcElement.scrollHeight || e.target.scrollHeight;
		if (scrollTop + height >= scrollHeight-20) {
			if (this.page[this.currentIndex] == this.pageCount) {
				return
			}else{
				this.page[this.currentIndex]++;
				this.recordList();
			}
		}
	},
  },
  
};
</script>

<style lang="scss" scoped>
.businessWrap{
		width: 100%;
		height: 100%;
		background-color: #F5F5F5;
		.swiperHead{
			display: flex;
			width: 100%;
			height: 84px;
			background-color: #fff;
			box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.06);
			.swiperBtn{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50%;
				height: 100%;
				.text{
					display: flex;
					align-items: center;
					height: 100%;
					font-size: 34px;
					color: #333333;
					font-weight: bold;
					height: 100%;
				}
				.currentText{
					color: #FF5000 !important;
					border-bottom: 4px solid #FF5000;
					box-sizing: border-box;
				}
			}
		}
		.businessContent{
			display: flex;
			flex-direction: column;
			width: 100%;
			height: calc(100% - 100px);
			overflow-y: scroll;
		}
	}
	
	
	
	
	/* scrolldiv */
	.scrolldiv{
		width: 100%;
		height: 100%;
	}
	.orderNodata{
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		color: #999999;
	}
	.orderNodata img{
		width: 354px;
		margin-top: 170px;
	}
	
	/* swiper组件 */
	.swiper {
		flex: 1;
	}
	.swiperItemWrap{
		padding: 0 30px 30px;
		box-sizing: border-box;
	}
	.swiperItem{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 34px 24px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 18px;
		margin-top: 30px;
	}
	.swiperItem_left{
		display: flex;
	}
	.swiperItem_left .left_icon{
		width: 84px;
		margin-right: 24px;
	}
	.swiperItem_left .swiperItem_left_title{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.swiperItem_left .swiperItem_left_title div:nth-child(1){
		color: #000000;
		font-size: 32px;
		font-weight: bold;
	}
	.swiperItem_left .swiperItem_left_title div:nth-child(2){
		color: #FF5000;
		font-size: 26px;
	}
	.swiperItem_right{
		border-radius: 50px;
		font-size: 26px;
		color: #FF5000;
		border: 1px solid #E3E3E3;
		padding: 12px 38px;
		box-sizing: border-box;
	}
</style>
